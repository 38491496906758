import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Fade } from "react-awesome-reveal";
import HelixIcon from "../components/helixIcon";
import WarpIcon from "../components/warpIcon";

const GetHelp = ({ data }) => {

  const heroContent = data.markdownRemark.frontmatter.hero;
  const html = data.markdownRemark.html

  return (
    <Layout classes="get-help">
      <Seo title={heroContent.title} description={heroContent.caption} />
      <section className="hero">
        <div className="container">
          <Fade triggerOnce>
            <p>{heroContent.small_title}</p>
            <h2>{heroContent.title}</h2>
          </Fade>
        </div>
      </section>
      <section className="article-content">
        <HelixIcon />
        <WarpIcon />
        <div className="container">
          <Fade triggerOnce>
            <section dangerouslySetInnerHTML={{ __html: html }} />
          </Fade>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`{
  markdownRemark(frontmatter: {template_key: {eq: "get-help"}}) {
    html
    frontmatter {
      title
      hero {
        small_title
        title
      }
    }
  }
}
`

export default GetHelp
